import { useIntl } from 'react-intl';
import { Col, Form } from 'antd';
import {
    MaxSafeNumber,
    ProductTypeName
} from '@olxeu-monetization/product-catalog-api-client';
import { FormFieldType, FormInput } from '../../components/FormInput/FormInput';
import { InputDurationFallbackMode } from '../../components/InputDuration';
import { PropertyLabel } from '../../components/PropertyLabel';
import { Section } from '../../components/Section';
import { Messages } from '../../intl';
import { KnownProductType, ListingFeeProductTypes } from '../../types/products';
import { useChangeObserver } from '../../utils';
import { ProductTypeTreeNode } from './types';

interface Props {
    productTypeTree: ProductTypeTreeNode[];
}

export const PropertiesFormSection = ({ productTypeTree }: Props) => {
    const { formatMessage } = useIntl();
    const form = Form.useFormInstance();
    const productType = Form.useWatch('type') as ProductTypeName | undefined;

    const isListingFeeProductType = ListingFeeProductTypes.includes(
        productType as KnownProductType
    );

    useChangeObserver(
        ([previousProductType]) => {
            if (previousProductType !== undefined) {
                form.setFieldsValue({
                    slots: isListingFeeProductType ? undefined : 1,
                    validity: undefined
                });
            } else if (!isListingFeeProductType) {
                form.setFieldsValue({
                    slots: 1
                });
            }
        },
        [productType]
    );

    return (
        <Section
            title={formatMessage(Messages['common.label.properties'])}
            data-testid="product-properties"
        >
            <Col span={8}>
                <FormInput
                    name="name"
                    label={
                        <PropertyLabel
                            title={formatMessage(Messages['common.label.name'])}
                            description={formatMessage(
                                Messages['common.description.product-name']
                            )}
                        />
                    }
                    required={true}
                    type={FormFieldType.Input}
                    autoFocus={true}
                />

                <FormInput
                    name="type"
                    label={
                        <PropertyLabel
                            title={formatMessage(Messages['common.label.type'])}
                            description={formatMessage(
                                Messages['common.description.product-type']
                            )}
                        />
                    }
                    required={true}
                    type={FormFieldType.TreeSelect}
                    treeData={productTypeTree}
                />

                <FormInput
                    name="validity"
                    label={
                        <PropertyLabel
                            title={formatMessage(
                                Messages['common.label.validity']
                            )}
                            description={formatMessage(
                                Messages['common.description.product-validity']
                            )}
                        />
                    }
                    required={false}
                    showExpirationSwitch={true}
                    type={FormFieldType.Duration}
                    fallbackMode={InputDurationFallbackMode.DisplayZero}
                    hideDisallowedSegments={true}
                />
                <FormInput
                    name="slots"
                    label={
                        <PropertyLabel
                            title={formatMessage(
                                Messages['common.label.slots']
                            )}
                            description={formatMessage(
                                Messages['common.description.product-slots']
                            )}
                        />
                    }
                    required={true}
                    disabled={!isListingFeeProductType}
                    type={FormFieldType.Number}
                    min={1}
                    max={MaxSafeNumber}
                />
                <FormInput
                    name="status"
                    label={
                        <PropertyLabel
                            title={formatMessage(
                                Messages['common.label.status']
                            )}
                            description={formatMessage(
                                Messages['common.description.product-status']
                            )}
                        />
                    }
                    required={true}
                    type={FormFieldType.Switch}
                    secondaryLabel={formatMessage(
                        Messages['common.label.enabled']
                    )}
                />
            </Col>
        </Section>
    );
};
