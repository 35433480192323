import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlag } from '../../types/flags';

type FlagValues = {
    [key in FeatureFlag]: boolean;
};

export const useFeatureFlag = (flagKey: FeatureFlag) => {
    const flags = useFlags<FlagValues>();

    return flags[flagKey];
};
