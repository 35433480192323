import { useContext, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    AugmentedRoute,
    AugmentedTableInterface,
    Button,
    Table,
    TablePaginationConfig,
    Tabs,
    Tag
} from 'antd';
import { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { PlusOutlined } from '@ant-design/icons';
import { useTheme } from 'styled-components';
import { AccessScope } from '@olxeu-monetization/auth-service-api-client';
import {
    OfferChange,
    OfferChangeStatus
} from '@olxeu-monetization/product-catalog-api-client';
import { PageHeader } from '../../components/PageHeader';
import { PermissionGuard } from '../../components/PermissionGuard';
import { ModalContext } from '../../context';
import { Messages } from '../../intl';
import { OfferChangeCreationModal } from '../../modals/OfferChangeCreationModal';
import { MarketId } from '../../routing';
import {
    buildTableColumns,
    Filters,
    TableDataSource,
    TableFilters
} from './columns';
import {
    buildOfferChangesData,
    mapTableFiltersToFilters,
    OfferChangeFiltersDefaultValues
} from './utils';

interface Props {
    marketId: MarketId;
    offerChanges: OfferChange[];
    offerChangesCount: number;
    breadcrumbRoutes: AugmentedRoute[];
    filters: Filters;
    pageIndex: number;
    pageSize: number;
    offerChangesLoading: boolean;
    onFiltersChange?: (filters: Filters) => void;
    onPageIndexChange?: (pageIndex: number) => void;
    onPageSizeChange?: (pageSize: number) => void;
}

const OfferChangeTable = Table as AugmentedTableInterface<
    TableDataSource,
    TableFilters
>;

enum OfferChangeStatusTab {
    All = 'ALL',
    Cancelled = 'CANCELLED',
    Draft = 'DRAFT',
    Published = 'PUBLISHED'
}

const tabsDataSource = [
    {
        id: OfferChangeStatusTab.All,
        name: (
            <FormattedMessage {...Messages['common.label.all-offer-changes']} />
        )
    },
    {
        id: OfferChangeStatusTab.Draft,
        name: <FormattedMessage {...Messages['common.label.draft']} />
    },
    {
        id: OfferChangeStatusTab.Published,
        name: <FormattedMessage {...Messages['common.label.published']} />
    },
    {
        id: OfferChangeStatusTab.Cancelled,
        name: <FormattedMessage {...Messages['common.label.cancelled']} />
    }
];

export const OfferChangeListLayout = ({
    marketId,
    offerChanges,
    offerChangesCount,
    breadcrumbRoutes,
    filters,
    pageIndex,
    pageSize,
    offerChangesLoading,
    onFiltersChange,
    onPageIndexChange,
    onPageSizeChange
}: Props) => {
    const theme = useTheme();
    const modal = useContext(ModalContext);
    const intl = useIntl();
    const { formatMessage } = intl;

    const dataSource = useMemo(
        () => buildOfferChangesData(offerChanges, marketId),
        [offerChanges, marketId]
    );

    const columns = useMemo(
        () => buildTableColumns({ intl, filters }),
        [intl, filters]
    );

    const handleChange = (
        pagination: TablePaginationConfig,
        tableFilters: TableFilters,
        sorter: SorterResult<TableDataSource> | SorterResult<TableDataSource>[],
        extra: TableCurrentDataSource<TableDataSource>
    ) => {
        switch (extra.action) {
            case 'paginate': {
                const nextPageIndex = Number(pagination.current) - 1;
                const nextPageSize = Number(pagination.pageSize);

                if (nextPageIndex !== pageIndex) {
                    onPageIndexChange?.(nextPageIndex);
                }

                if (nextPageSize !== pageSize) {
                    onPageSizeChange?.(nextPageSize);
                }

                break;
            }
            case 'filter': {
                const nextFilters = mapTableFiltersToFilters(tableFilters);
                onFiltersChange?.(nextFilters);
                break;
            }
            default:
                return;
        }
    };

    const handleStatusChange = (status: string) => {
        switch (status) {
            case OfferChangeStatusTab.All: {
                onFiltersChange?.({
                    ...OfferChangeFiltersDefaultValues
                });
                break;
            }
            case OfferChangeStatusTab.Cancelled: {
                onFiltersChange?.({
                    ...OfferChangeFiltersDefaultValues,
                    status: OfferChangeStatus.Cancelled
                });
                break;
            }
            case OfferChangeStatusTab.Draft: {
                onFiltersChange?.({
                    ...OfferChangeFiltersDefaultValues,
                    status: OfferChangeStatus.Draft
                });
                break;
            }
            case OfferChangeStatusTab.Published: {
                onFiltersChange?.({
                    ...OfferChangeFiltersDefaultValues,
                    status: OfferChangeStatus.Published
                });
                break;
            }
        }
    };

    const handleOfferChangeCreate = () => {
        modal.show(<OfferChangeCreationModal marketId={marketId} />, {
            footer: null
        });
    };

    const statusTabs = tabsDataSource.map((tab) => ({
        label: tab.name,
        key: tab.id,
        children: (
            <OfferChangeTable
                data-testid="offer-changes-table"
                columns={columns}
                dataSource={dataSource}
                loading={offerChangesLoading}
                pagination={{
                    total: offerChangesCount,
                    pageSize,
                    current: pageIndex + 1
                }}
                onChange={handleChange}
            />
        )
    }));

    const activeTab = filters.status ?? OfferChangeStatusTab.All;

    return (
        <div
            data-testid="offer-change-list-layout"
            className="tab-navigation-list"
        >
            <PageHeader
                title={formatMessage(Messages['common.label.offer-changes'])}
                description={intl.formatMessage(
                    Messages['common.description.offer-change-list']
                )}
                tags={
                    offerChangesCount ? (
                        <Tag color={theme.colors.tagCounter}>
                            {formatMessage(Messages['common.tag.total-count'], {
                                count: offerChangesCount
                            })}
                        </Tag>
                    ) : undefined
                }
                breadcrumbRoutes={breadcrumbRoutes}
                actions={[
                    <PermissionGuard
                        permissions={[
                            AccessScope.ProductCatalogOfferChangeWrite
                        ]}
                        key="create-offer-change"
                    >
                        {(eligible) => (
                            <Button
                                icon={<PlusOutlined />}
                                disabled={!eligible}
                                onClick={handleOfferChangeCreate}
                            >
                                {formatMessage(
                                    Messages[
                                        'common.button.create-new-offer-change'
                                    ]
                                )}
                            </Button>
                        )}
                    </PermissionGuard>
                ]}
                hideBackButton={true}
            />
            <Tabs
                onChange={handleStatusChange}
                type="card"
                activeKey={activeTab}
                items={statusTabs}
            />
        </div>
    );
};
