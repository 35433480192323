import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Popconfirm } from 'antd';
import { Messages } from '../../intl';

interface Props {
    title: ReactNode;
    disabled?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
    children: ReactNode;
}

export const ConfirmAction = ({
    title,
    disabled,
    onConfirm,
    onCancel,
    children
}: Props) => {
    const { formatMessage } = useIntl();

    return (
        <Popconfirm
            title={title}
            disabled={disabled}
            onConfirm={onConfirm}
            onCancel={onCancel}
            okText={formatMessage(Messages['common.label.yes'])}
            cancelText={formatMessage(Messages['common.label.no'])}
        >
            {children}
        </Popconfirm>
    );
};
