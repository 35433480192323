import queryString, { StringifiableRecord } from 'query-string';

import { HTTPError } from './error';
import { MarketId } from './models';

export interface RequestContext {
    baseUrl: string;
    accessToken?: string;
    market?: MarketId;
}

const getHeaders = (context?: RequestContext): HeadersInit => {
    const headers: HeadersInit = {};

    if (context?.accessToken) {
        headers['Authorization'] = `Bearer ${context.accessToken}`;
    }

    if (context?.market) {
        headers['market'] = context.market;
    }

    return headers;
};

interface FetchDataOptions {
    path: string;
    context: RequestContext;
    baseInit?: Parameters<typeof fetch>[1];
    query?: StringifiableRecord;
}

export const fetchData = async <RequestResult>({
    path,
    context,
    baseInit = {},
    query
}: FetchDataOptions) => {
    const headers = {
        ...baseInit.headers,
        ...getHeaders(context)
    };

    const url = queryString.stringifyUrl({
        url: `${context.baseUrl}${path}`,
        query
    });

    const init = {
        ...baseInit,
        headers
    };

    const response = await fetch(url, init);

    if (!response.ok) {
        const { status, statusText, url } = response;
        throw new HTTPError(
            `Error fetching ${url}: ${statusText} (${status})`,
            status
        );
    }

    return (await response.json()) as RequestResult;
};
