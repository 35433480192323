import { Navigate, Outlet } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import { AppLayout } from '../layouts/AppLayout';
import { GuestAppLayout } from '../layouts/GuestAppLayout';
import { CategorySetCreatePage } from '../pages/CategorySetCreatePage';
import { CategorySetDetailsPage } from '../pages/CategorySetDetailsPage';
import { CategorySetEditPage } from '../pages/CategorySetEditPage';
import { CategorySetListPage } from '../pages/CategorySetListPage';
import { LimitCreatePage } from '../pages/LimitCreatePage';
import { LimitDetailsPage } from '../pages/LimitDetailsPage';
import { LimitEditPage } from '../pages/LimitEditPage';
import { LimitListPage } from '../pages/LimitListPage';
import { OfferChangeDetailsPage } from '../pages/OfferChangeDetailsPage';
import { OfferChangeListPage } from '../pages/OfferChangeListPage';
import { OfferChangeProductCreatePage } from '../pages/OfferChangeProductCreatePage';
import { OfferChangeProductDetailsPage } from '../pages/OfferChangeProductDetailsPage';
import { OfferChangeProductEditPage } from '../pages/OfferChangeProductEditPage';
import { ProductDetailsPage } from '../pages/ProductDetailsPage';
import { ProductEditPage } from '../pages/ProductEditPage';
import { ProductListPage } from '../pages/ProductListPage';
import { SignInPage } from '../pages/SignInPage';
import { TemplateCreatePage } from '../pages/TemplateCreatePage';
import { TemplateDetailsPage } from '../pages/TemplateDetailsPage';
import { TemplateEditPage } from '../pages/TemplateEditPage';
import { TemplateListPage } from '../pages/TemplateListPage';
import { CategoriesDataContextProvider } from '../providers/CategoriesDataContextProvider';
import { FeaturesDataContextProvider } from '../providers/FeaturesDataContextProvider';
import { MarketLanguagesDataContextProvider } from '../providers/MarketLanguagesDataContextProvider';
import { ProductTypesDataContextProvider } from '../providers/ProductTypesDataContextProvider';
import { MarketGuard } from './guards';
import { Path } from './paths';
import { MarketRedirection, ProductListRedirection } from './redirections';
import { ApplicationRouteObject, RouteUserAccess } from './types';

export const routes: ApplicationRouteObject[] = [
    {
        path: Path.Home,
        userAccess: RouteUserAccess.Authenticated,
        element: <AppLayout />,
        children: [
            {
                index: true,
                userAccess: RouteUserAccess.Any,
                element: <MarketRedirection />
            },
            {
                path: Path.Market,
                userAccess: RouteUserAccess.Any,
                element: (
                    <MarketGuard>
                        <FeaturesDataContextProvider>
                            <ProductTypesDataContextProvider>
                                <CategoriesDataContextProvider>
                                    <MarketLanguagesDataContextProvider>
                                        <Outlet />
                                    </MarketLanguagesDataContextProvider>
                                </CategoriesDataContextProvider>
                            </ProductTypesDataContextProvider>
                        </FeaturesDataContextProvider>
                    </MarketGuard>
                ),
                children: [
                    {
                        index: true,
                        userAccess: RouteUserAccess.Any,
                        element: <ProductListRedirection />
                    },
                    {
                        path: Path.ProductList,
                        userAccess: RouteUserAccess.Any,
                        element: <ProductListPage />
                    },
                    {
                        path: Path.ProductDetails,
                        userAccess: RouteUserAccess.Any,
                        element: <ProductDetailsPage />
                    },
                    {
                        path: Path.ProductEdit,
                        userAccess: RouteUserAccess.Any,
                        element: <ProductEditPage />
                    },
                    {
                        path: Path.OfferChangeList,
                        userAccess: RouteUserAccess.Any,
                        element: <OfferChangeListPage />
                    },
                    {
                        path: Path.OfferChangeDetails,
                        userAccess: RouteUserAccess.Any,
                        element: <OfferChangeDetailsPage />
                    },
                    {
                        path: Path.OfferChangeProductDetails,
                        userAccess: RouteUserAccess.Any,
                        element: <OfferChangeProductDetailsPage />
                    },
                    {
                        path: Path.OfferChangeProductCreate,
                        userAccess: RouteUserAccess.Any,
                        element: <OfferChangeProductCreatePage />
                    },
                    {
                        path: Path.OfferChangeProductEdit,
                        userAccess: RouteUserAccess.Any,
                        element: <OfferChangeProductEditPage />
                    },
                    {
                        path: Path.CategorySetList,
                        userAccess: RouteUserAccess.Any,
                        element: <CategorySetListPage />
                    },
                    {
                        path: Path.CategorySetDetails,
                        userAccess: RouteUserAccess.Any,
                        element: <CategorySetDetailsPage />
                    },
                    {
                        path: Path.CategorySetCreate,
                        userAccess: RouteUserAccess.Any,
                        element: <CategorySetCreatePage />
                    },
                    {
                        path: Path.CategorySetEdit,
                        userAccess: RouteUserAccess.Any,
                        element: <CategorySetEditPage />
                    },
                    {
                        path: Path.LimitList,
                        userAccess: RouteUserAccess.Any,
                        element: <LimitListPage />
                    },
                    {
                        path: Path.LimitDetails,
                        userAccess: RouteUserAccess.Any,
                        element: <LimitDetailsPage />
                    },
                    {
                        path: Path.LimitCreate,
                        userAccess: RouteUserAccess.Any,
                        element: <LimitCreatePage />
                    },
                    {
                        path: Path.LimitEdit,
                        userAccess: RouteUserAccess.Any,
                        element: <LimitEditPage />
                    },
                    {
                        path: '*',
                        userAccess: RouteUserAccess.Any,
                        element: <Navigate to={Path.Home} />
                    },
                    {
                        path: Path.TemplateList,
                        userAccess: RouteUserAccess.Any,
                        element: <TemplateListPage />
                    },
                    {
                        path: Path.TemplateDetails,
                        userAccess: RouteUserAccess.Any,
                        element: <TemplateDetailsPage />
                    },
                    {
                        path: Path.TemplateCreate,
                        userAccess: RouteUserAccess.Any,
                        element: <TemplateCreatePage />
                    },
                    {
                        path: Path.TemplateEdit,
                        userAccess: RouteUserAccess.Any,
                        element: <TemplateEditPage />
                    }
                ]
            }
        ]
    },
    {
        path: Path.Home,
        userAccess: RouteUserAccess.Guest,
        element: <GuestAppLayout />,
        children: [
            {
                index: true,
                userAccess: RouteUserAccess.Any,
                element: <Navigate to={Path.SignIn} />
            },
            {
                path: Path.SignIn,
                userAccess: RouteUserAccess.Any,
                element: <SignInPage />
            },
            {
                path: Path.Market,
                userAccess: RouteUserAccess.Any,
                element: (
                    <MarketGuard>
                        <Outlet />
                    </MarketGuard>
                ),
                children: [
                    {
                        index: true,
                        userAccess: RouteUserAccess.Any,
                        element: <Navigate to={Path.Home} />
                    },
                    {
                        path: '*',
                        userAccess: RouteUserAccess.Any,
                        element: <Navigate to={Path.Home} />
                    }
                ]
            }
        ]
    },
    {
        path: Path.SignInCallback,
        userAccess: RouteUserAccess.Any,
        element: <LoginCallback />
    },
    {
        path: Path.SignOutCallback,
        userAccess: RouteUserAccess.Any,
        element: <Navigate to={Path.SignIn} />
    }
];
