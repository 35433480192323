import { useIntl } from 'react-intl';
import { AugmentedRoute, Tag } from 'antd';
import { useTheme } from 'styled-components';
import {
    Feature,
    MarketLanguage,
    OfferChangeProductDetails,
    ProductDetails
} from '@olxeu-monetization/product-catalog-api-client';
import { EditProductAction } from '../../components/EditProductAction';
import { PageHeader } from '../../components/PageHeader';
import { ProductCategorySets } from '../../components/ProductSections/ProductCategorySets';
import { ProductFeatures } from '../../components/ProductSections/ProductFeatures';
import { ProductLabels } from '../../components/ProductSections/ProductLabels';
import { ProductLocales } from '../../components/ProductSections/ProductLocales';
import { ProductProperties } from '../../components/ProductSections/ProductProperties';
import { FeaturePropertyValidationMap } from '../../context';
import { Messages } from '../../intl';
import { MarketId } from '../../routing';
import { ProductTypeTreeNode } from '../ProductFormLayout/types';

interface Props {
    title: string;
    product: ProductDetails | OfferChangeProductDetails;
    showEditButton?: boolean;
    features: Feature[];
    featurePropertyValidationMap: FeaturePropertyValidationMap;
    productTypeTree: ProductTypeTreeNode[];
    breadcrumbRoutes: AugmentedRoute[];
    marketLanguages: MarketLanguage[];
    marketId: MarketId;
}

export const ProductDetailsLayout = ({
    title,
    showEditButton = true,
    product,
    features,
    featurePropertyValidationMap,
    productTypeTree,
    breadcrumbRoutes,
    marketLanguages,
    marketId
}: Props) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();

    const editButton = (
        <EditProductAction
            key="edit-product"
            productId={product.id}
            offerChangeId={product.lockedBy}
            marketId={marketId}
        />
    );

    return (
        <div data-testid="product-details-layout">
            <PageHeader
                title={title}
                tags={
                    <Tag color={theme.colors.tagIdentifier}>
                        {formatMessage(Messages['common.tag.id'], {
                            id: product.id
                        })}
                    </Tag>
                }
                breadcrumbRoutes={breadcrumbRoutes}
                actions={showEditButton ? [editButton] : []}
            />
            <ProductProperties
                product={product}
                productTypeTree={productTypeTree}
            />
            <ProductCategorySets
                categorySetIds={product.target.categorySetIds}
            />
            <ProductLocales
                localizations={product.localizations}
                marketLanguages={marketLanguages}
            />
            <ProductFeatures
                features={product.features}
                featurePropertyValidationMap={featurePropertyValidationMap}
                featuresTotalCount={features.length}
            />
            {product.labels.length > 0 && (
                <ProductLabels
                    labels={product.labels}
                    features={product.features}
                    marketLanguages={marketLanguages}
                />
            )}
        </div>
    );
};
