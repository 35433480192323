import { ReactNode, useContext, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, useOktaAuth } from '@okta/okta-react';
import { AuthContext, ConfigContext } from '../../context';
import { MarketId } from '../../routing';
import { getMarketsFromJwtRoles, parseJwt } from './helpers';
import { OktaJwt } from './types';

interface AuthContextProviderInternalProps {
    children?: ReactNode;
}

const AuthContextProviderInternal = ({
    children
}: AuthContextProviderInternalProps) => {
    const { authState, oktaAuth } = useOktaAuth();
    const [currentMarketId, setCurrentMarketId] = useState<
        MarketId | undefined
    >();

    const location = useLocation();
    const siteId = location.pathname.split('/')[1];

    const authorizedMarketScopes = useMemo(() => {
        if (!authState?.idToken?.idToken) {
            return undefined;
        }

        const jwt = parseJwt<OktaJwt>(authState?.idToken?.idToken);

        return jwt.groups;
    }, [authState?.idToken?.idToken]);

    const authorizedMarketId = useMemo(() => {
        if (!authState?.idToken?.idToken) {
            return undefined;
        }

        const jwt = parseJwt<OktaJwt>(authState?.idToken?.idToken);

        if (jwt.aud === undefined) {
            throw new Error('User session contains no client id');
        }

        const marketIds = getMarketsFromJwtRoles(
            authorizedMarketScopes ?? [],
            MarketId
        );

        return (
            marketIds.find((marketId) => marketId === siteId) ?? marketIds[0]
        );
    }, [authState?.idToken?.idToken, authorizedMarketScopes, siteId]);

    if (!currentMarketId && authorizedMarketId) {
        setCurrentMarketId(authorizedMarketId);
    }

    const context = useMemo(() => {
        const handleUserMarketIdChange = (marketId: MarketId) => {
            const marketIds = getMarketsFromJwtRoles(
                authorizedMarketScopes ?? [],
                MarketId
            );

            if (!marketIds.includes(marketId)) {
                throw new Error('Unauthorized market id');
            }

            setCurrentMarketId(marketId);
        };

        return {
            authenticated: authState?.isAuthenticated ?? false,
            getAccessToken: () => Promise.resolve(authState?.idToken?.idToken),
            userMarketId: authorizedMarketId,
            userMarketScopes: authorizedMarketScopes,
            setUserMarketId: handleUserMarketIdChange,
            getUserInfo: () => oktaAuth.getUser(),
            logout: () => oktaAuth.signOut()
        };
    }, [
        authState?.isAuthenticated,
        authState?.idToken?.idToken,
        authorizedMarketId,
        authorizedMarketScopes,
        oktaAuth
    ]);

    // NOTE: empty `authState` means session is being initialized; after initialization, the component
    // will rerender with non-empty `authState`, where `isAuthenticated` can be either `true` or `false`;
    // in case if we land on login callback url, the `authState` will always be null; for this use case,
    // we allow our app to render routing and consume login callback codes.
    if (!authState && !oktaAuth.isLoginRedirect()) {
        return null;
    }

    return (
        <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
    );
};

interface AuthContextProviderProps {
    children?: ReactNode;
}

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
    const config = useContext(ConfigContext);
    const navigate = useNavigate();

    const oktaAuth = useMemo(() => {
        const {
            clientId,
            issuer,
            redirectUri,
            postLogoutRedirectUri,
            scopes,
            pkce
        } = config.services.oktaAuthService;

        return new OktaAuth({
            clientId,
            issuer,
            redirectUri,
            postLogoutRedirectUri,
            scopes,
            pkce
        });
    }, [config.services.oktaAuthService]);

    const restoreOriginalUri = (oktaAuth: OktaAuth, originalUri: string) => {
        const nextUrl = toRelativeUrl(
            originalUri || '/',
            window.location.origin
        );

        navigate(nextUrl, { replace: true });
    };

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <AuthContextProviderInternal>
                {children}
            </AuthContextProviderInternal>
        </Security>
    );
};
