import { ReactNode, useContext, useMemo } from 'react';
import { AuthContext, UserScopesContext } from '../../context';
import { useAuthService } from '../../helpers';
import { usePromise } from '../../utils';

interface Props {
    children?: ReactNode;
}

export const UserScopesContextProvider = ({ children }: Props) => {
    const auth = useContext(AuthContext);
    const { getScopes } = useAuthService();

    const { data, loading, error } = usePromise({
        disableAutostart: !auth.authenticated,
        variables: {
            marketId: auth.userMarketId // This variable is not consumed, yet has to be here to refresh scopes on market change
        },
        promiseBuilder: async () => {
            return getScopes();
        }
    });

    const context = useMemo(() => {
        return {
            data,
            loading,
            error
        };
    }, [data, error, loading]);

    return (
        <UserScopesContext.Provider value={context}>
            {children}
        </UserScopesContext.Provider>
    );
};
