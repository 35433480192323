import { createContext } from 'react';
import { OktaAccessScope } from '../providers';
import { MarketId } from '../routing';
import { UserInfo } from './UserDataContext';

export interface AuthContextType {
    authenticated: boolean;
    getAccessToken: () => Promise<string | undefined>;
    userMarketId?: MarketId;
    userMarketScopes?: OktaAccessScope[];
    setUserMarketId?: (marketId: MarketId) => void;
    getUserInfo: () => Promise<UserInfo>;
    logout: () => Promise<void>;
}

export const DefaultAuthContext: AuthContextType = {
    authenticated: false,
    getAccessToken: () => Promise.reject(new Error('Unimplemented')),
    userMarketId: undefined,
    userMarketScopes: undefined,
    setUserMarketId: () => {
        throw new Error('Unimplemented');
    },
    getUserInfo: () => Promise.reject(new Error('Unimplemented')),
    logout: () => Promise.reject(new Error('Unimplemented'))
};

export const AuthContext = createContext(DefaultAuthContext);
