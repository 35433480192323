import { ReactNode, useContext } from 'react';
import { LDContext, LDProvider } from 'launchdarkly-react-client-sdk';
import { AuthContext, ConfigContext, UserDataContext } from '../../context';

interface Props {
    children: ReactNode;
}

export const LaunchDarklyProvider = ({ children }: Props) => {
    const config = useContext(ConfigContext);
    const userData = useContext(UserDataContext);
    const { userMarketId } = useContext(AuthContext);

    if (userData.loading) {
        return null;
    }

    const userEmail = userData.data?.email;

    const context: LDContext = {
        kind: 'user',
        key: `user-key-${userEmail || 'anonymous'}`,
        country: userMarketId,
        email: userEmail
    };

    return (
        <LDProvider
            clientSideID={config.application.launchDarklyClientId}
            reactOptions={{
                useCamelCaseFlagKeys: false
            }}
            context={context}
        >
            {children}
        </LDProvider>
    );
};
