import { ReactNode, useContext } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { AuthContext, UserScopesContext } from '../../context';
import {
    getMarketsFromJwtRoles,
    hasProductCatalogUiAccess
} from '../../providers/AuthContextProvider/helpers';
import { MarketId, Path } from '../paths';

type Params = {
    marketId: MarketId;
};

interface Props {
    children?: ReactNode;
}

export const MarketGuard = ({ children }: Props) => {
    const auth = useContext(AuthContext);
    const userScopes = useContext(UserScopesContext);
    const params = useParams<Params>();

    const marketIds = getMarketsFromJwtRoles(
        auth.userMarketScopes ?? [],
        MarketId
    );
    const marketId = marketIds.find((marketId) => {
        return marketId === params.marketId;
    });

    // NOTE: market requested in the URL doesn't exist
    if (marketId === undefined) {
        return <Navigate to={Path.Home} />;
    }

    if (userScopes.loading) {
        return null;
    }

    // NOTE: user is not authorized for requested tool
    const hasProductCatalogReadAccess = auth.userMarketScopes?.some((scope) => {
        return (
            userScopes.data && hasProductCatalogUiAccess(userScopes.data.scopes)
        );
    });

    if (!hasProductCatalogReadAccess) {
        throw new Error(
            'User has no access to Product Catalog for this market'
        );
    }

    return <>{children}</>;
};
