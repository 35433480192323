import { ConfigContextType } from '../context';
import { Path } from '../routing';

export const Config: ConfigContextType = {
    services: {
        authService: {
            baseUrl: process.env.AUTH_SERVICE_BASE_URL
        },
        productCatalogService: {
            baseUrl: process.env.PRODUCT_CATALOG_SERVICE_BASE_URL
        },
        upsService: {
            baseUrl: process.env.UPS_SERVICE_BASE_URL
        },
        oktaAuthService: {
            clientId: process.env.OKTA_AUTH_SERVICE_CLIENT_ID,
            issuer: process.env.OKTA_AUTH_SERVICE_ISSUER,
            redirectUri: `${window.location.origin}${Path.SignInCallback}`,
            postLogoutRedirectUri: `${window.location.origin}${Path.SignOutCallback}`,
            scopes: ['openid', 'profile', 'email', 'groups'],
            pkce: true
        }
    },
    application: {
        environment: process.env.APP_ENV,
        launchDarklyClientId: process.env.LAUNCH_DARKLY_CLIENT_ID
    },
    intl: {
        locale: window.navigator.language
    }
};
