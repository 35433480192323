import { IntlShape } from 'react-intl';
import {
    OfferChangeProductDetails,
    ProductDetails
} from '@olxeu-monetization/product-catalog-api-client';
import { Messages } from '../../../intl';
import { ProductStatusTag } from '../../StatusTag';
import { getHumanTimeLabel } from '../utils';

export const buildDataSource = (
    product: ProductDetails | OfferChangeProductDetails | undefined,
    previousProduct: ProductDetails | undefined,
    intl: IntlShape
) => {
    const nameChanged =
        previousProduct !== undefined && product?.name !== previousProduct.name;
    const validityChanged =
        previousProduct !== undefined &&
        product?.validity !== previousProduct.validity;
    const typeChanged =
        previousProduct !== undefined && product?.type !== previousProduct.type;
    const slotsChanged =
        previousProduct !== undefined &&
        product?.slots !== previousProduct.slots;
    const invoicingIdChanged =
        previousProduct !== undefined &&
        product?.invoicingId !== previousProduct.invoicingId;
    const statusChanged =
        previousProduct !== undefined &&
        product?.status !== previousProduct.status;

    const previousValidity =
        previousProduct && getHumanTimeLabel(previousProduct.validity, intl);

    const previousStatus = previousProduct && (
        <ProductStatusTag status={previousProduct.status} />
    );

    const dataSource = [
        {
            id: 'name',
            label: intl.formatMessage(Messages['common.label.name']),
            description: intl.formatMessage(
                Messages['common.description.product-name']
            ),
            value: product?.name,
            previousValue: nameChanged ? previousProduct?.name : undefined
        },
        {
            id: 'validity',
            label: intl.formatMessage(Messages['common.label.validity']),
            description: intl.formatMessage(
                Messages['common.description.product-validity']
            ),
            value: product && getHumanTimeLabel(product.validity, intl),
            previousValue: validityChanged ? previousValidity : undefined
        },
        {
            id: 'type',
            label: intl.formatMessage(Messages['common.label.type']),
            description: intl.formatMessage(
                Messages['common.description.product-type']
            ),
            value: product?.type,
            previousValue: typeChanged ? previousProduct?.type : undefined
        },
        {
            id: 'slots',
            label: intl.formatMessage(Messages['common.label.slots']),
            description: intl.formatMessage(
                Messages['common.description.product-slots']
            ),
            value: product?.slots,
            previousValue: slotsChanged ? previousProduct?.slots : undefined
        },
        ...(product?.externalId
            ? [
                  {
                      id: 'external-id',
                      label: intl.formatMessage(
                          Messages['common.label.external-id']
                      ),
                      description: intl.formatMessage(
                          Messages['common.description.product-external-id']
                      ),
                      value: product?.externalId,
                      previousValue: undefined
                  }
              ]
            : []),
        {
            id: 'status',
            label: intl.formatMessage(Messages['common.label.status']),
            description: intl.formatMessage(
                Messages['common.description.product-status']
            ),
            value: product && <ProductStatusTag status={product.status} />,
            previousValue: statusChanged ? previousStatus : undefined
        }
    ];

    const changesCount =
        Number(nameChanged) +
        Number(validityChanged) +
        Number(typeChanged) +
        Number(slotsChanged) +
        Number(invoicingIdChanged) +
        Number(statusChanged);

    return {
        dataSource,
        changesCount
    };
};
