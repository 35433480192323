import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { AugmentedRoute, Form, Tag } from 'antd';
import { FieldData } from 'rc-field-form/lib/interface';
import { useTheme } from 'styled-components';
import {
    Category,
    CategorySetId,
    Feature,
    MarketLanguage,
    OfferChangeProductDetails,
    ProductDetails,
    ProductType,
    Template
} from '@olxeu-monetization/product-catalog-api-client';
import { BottomBar } from '../../components/BottomBar';
import { PageHeader } from '../../components/PageHeader';
import { FeaturePropertyValidationMap } from '../../context';
import { Messages } from '../../intl';
import { CategorySetsFormSection } from './CategorySetsFormSection';
import { FeaturesFormSection } from './FeaturesFormSection';
import {
    createProductTypeTree,
    mapFormValuesToFormPayload,
    mapProductToFormValues
} from './helpers';
import { LabelsFormSection } from './LabelsFormSection';
import { LocalizationsFormSection } from './LocalizationsFormSection';
import { PropertiesFormSection } from './PropertiesFormSection';
import {
    ProductFormPayload,
    ProductFormValues,
    TemplateTranslations
} from './types';

interface Props {
    title: string;
    product?: ProductDetails | OfferChangeProductDetails;
    features: Feature[];
    featurePropertyValidationMap: FeaturePropertyValidationMap;
    breadcrumbRoutes: AugmentedRoute[];
    productTypes: ProductType[];
    marketLanguages: MarketLanguage[];
    categories: Category[];
    categorySetNamesMap: Map<CategorySetId, string>;
    categorySetsTotalCount: number;
    renderSubmitButton?: (
        hasErrors: boolean,
        hasUndefinedTranslation: boolean
    ) => ReactNode;
    onSubmit?: (product: ProductFormPayload) => void;
}

export const ProductFormLayout = ({
    title,
    product,
    features,
    featurePropertyValidationMap,
    breadcrumbRoutes,
    productTypes,
    marketLanguages,
    categories,
    categorySetNamesMap,
    categorySetsTotalCount,
    renderSubmitButton,
    onSubmit
}: Props) => {
    const theme = useTheme();
    const intl = useIntl();
    const { formatMessage } = intl;
    const [form] = Form.useForm();
    const [fieldErrorList, setFieldErrorList] = useState<FieldData[]>([]);
    const [templatesTranslationMap, setTemplatesTranslationMap] =
        useState<TemplateTranslations>({});

    const [initialValues] = useState<ProductFormValues>(() => {
        return mapProductToFormValues(
            product,
            featurePropertyValidationMap,
            marketLanguages,
            categorySetNamesMap
        );
    });

    const productTypeTree = createProductTypeTree(productTypes);

    const handleFieldsChanged = (
        changedFields: FieldData[],
        allFields: FieldData[]
    ) => {
        const fieldsWithError = allFields.filter(
            (field) => field.errors?.length
        );
        setFieldErrorList(fieldsWithError);
    };

    const handleSubmit = (values: ProductFormValues) => {
        const productPayload = mapFormValuesToFormPayload(
            values,
            featurePropertyValidationMap
        );
        onSubmit?.(productPayload);
    };

    const handleTemplateSelect = (template: Template) => {
        const currentTemplateTranslations = template.translations.reduce<
            TemplateTranslations[string]
        >((acc, translation) => {
            return {
                ...acc,
                [translation.language]: translation.value
            };
        }, {});

        setTemplatesTranslationMap((templatesTranslationMap) => {
            return {
                ...templatesTranslationMap,
                [template.id]: currentTemplateTranslations
            };
        });
    };

    const handleTemplateRemove = (templateId: string) => {
        setTemplatesTranslationMap((templatesTranslationMap) => {
            const { [templateId]: _, ...rest } = templatesTranslationMap;
            return rest;
        });
    };

    const hasUndefinedTranslationForCurrentMarket = Object.values(
        templatesTranslationMap
    ).some((translation) => {
        return !marketLanguages.some((lang) => {
            return translation[lang.code];
        });
    });

    return (
        <>
            <Form
                form={form}
                onFinish={handleSubmit}
                onFieldsChange={handleFieldsChanged}
                initialValues={initialValues}
                layout="vertical"
                scrollToFirstError={true}
                data-testid="product-form-layout"
                className="form-with-enhanced-labels"
            >
                <PageHeader
                    title={title}
                    tags={
                        product && (
                            <Tag color={theme.colors.tagIdentifier}>
                                {formatMessage(Messages['common.tag.id'], {
                                    id: product.id
                                })}
                            </Tag>
                        )
                    }
                    breadcrumbRoutes={breadcrumbRoutes}
                />
                <PropertiesFormSection productTypeTree={productTypeTree} />
                <CategorySetsFormSection
                    categories={categories}
                    categorySetsTotalCount={categorySetsTotalCount}
                    productTypes={productTypes}
                />
                <LocalizationsFormSection />
                <FeaturesFormSection
                    features={features}
                    featurePropertyValidationMap={featurePropertyValidationMap}
                />
                <LabelsFormSection
                    marketLanguages={marketLanguages}
                    onTemplateSelect={handleTemplateSelect}
                    onTemplateRemove={handleTemplateRemove}
                />
                <BottomBar>
                    <Form.Item noStyle={true}>
                        {renderSubmitButton?.(
                            fieldErrorList.length > 0,
                            hasUndefinedTranslationForCurrentMarket
                        )}
                    </Form.Item>
                </BottomBar>
            </Form>
        </>
    );
};
