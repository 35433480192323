import { ReactElement, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { AccessScope } from '@olxeu-monetization/auth-service-api-client';
import { UserScopesContext } from '../../context';
import { Messages } from '../../intl';
import { isPermissionGrantedForScope } from './utils';

interface Props {
    permissions: AccessScope[];
    placement?: TooltipPlacement;
    children: (eligible: boolean) => ReactElement;
}

export const PermissionGuard = ({
    permissions,
    placement,
    children
}: Props) => {
    const userScopes = useContext(UserScopesContext);
    const { formatMessage } = useIntl();

    const permissionGranted = useMemo(() => {
        if (!userScopes.data) {
            return false;
        }
        return isPermissionGrantedForScope(userScopes.data.scopes, permissions);
    }, [permissions, userScopes.data]);

    if (permissionGranted) {
        return children(true);
    }

    return (
        <Tooltip
            title={formatMessage(
                Messages['common.tooltip-message.permission-warning']
            )}
            placement={placement}
        >
            {children(false)}
        </Tooltip>
    );
};
