import { ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { DollarOutlined, ProfileFilled } from '@ant-design/icons';
import { SpaceProps } from 'styled-system';
import { Box } from '../../components/Box';
import { Messages } from '../../intl';
import { buildLink, MarketId, Path } from '../../routing';
import { traverseTree, TreeTraversalMethod } from '../../utils';

interface MenuEntry {
    key: string;
    label: ReactNode;
    icon?: ReactNode;
    path?: string;
    disabled?: boolean;
    children?: MenuEntry[];
}

const buildMenuEntries = (marketId: MarketId): MenuEntry[] => {
    return [
        {
            key: 'TariffTester',
            label: (
                <FormattedMessage {...Messages['common.label.tariff-tester']} />
            ),
            icon: <DollarOutlined />,
            disabled: true
        },
        {
            key: 'DiscountDealer',
            label: (
                <FormattedMessage
                    {...Messages['common.label.discount-dealer']}
                />
            ),
            icon: <DollarOutlined />,
            disabled: true
        },
        {
            key: 'PriceManager',
            label: (
                <FormattedMessage {...Messages['common.label.price-manager']} />
            ),
            icon: <DollarOutlined />,
            disabled: true
        },
        {
            key: 'ProductCatalog',
            label: (
                <FormattedMessage
                    {...Messages['common.label.product-catalog']}
                />
            ),
            icon: <ProfileFilled />,
            children: [
                {
                    key: 'ProductCatalogProductList',
                    label: (
                        <Link to={buildLink(Path.ProductList, { marketId })}>
                            <FormattedMessage
                                {...Messages['common.label.products']}
                            />
                        </Link>
                    ),
                    path: buildLink(Path.ProductList, { marketId })
                },
                {
                    key: 'ProductCatalogOfferChangeList',
                    label: (
                        <Link
                            to={buildLink(Path.OfferChangeList, { marketId })}
                        >
                            <FormattedMessage
                                {...Messages['common.label.offer-changes']}
                            />
                        </Link>
                    ),
                    path: buildLink(Path.OfferChangeList, { marketId })
                },
                {
                    key: 'ProductCatalogCategorySetList',
                    label: (
                        <Link
                            to={buildLink(Path.CategorySetList, { marketId })}
                        >
                            <FormattedMessage
                                {...Messages['common.label.category-sets']}
                            />
                        </Link>
                    ),
                    path: buildLink(Path.CategorySetList, { marketId })
                },
                {
                    key: 'ProductCatalogLimitList',
                    label: (
                        <Link to={buildLink(Path.LimitList, { marketId })}>
                            <FormattedMessage
                                {...Messages['common.label.limits']}
                            />
                        </Link>
                    ),
                    path: buildLink(Path.LimitList, { marketId })
                },
                {
                    key: 'ProductCatalogTemplateList',
                    label: (
                        <Link
                            to={buildLink(Path.TemplateList, {
                                marketId
                            })}
                        >
                            <FormattedMessage
                                {...Messages['common.label.templates']}
                            />
                        </Link>
                    ),
                    path: buildLink(Path.TemplateList, { marketId })
                }
            ]
        }
    ];
};

interface Props extends SpaceProps {
    collapsed: boolean;
    marketId: MarketId;
}

export const SidebarMenu = ({ collapsed, marketId, ...rest }: Props) => {
    const { pathname } = useLocation();

    const menuEntries = useMemo(() => {
        return buildMenuEntries(marketId);
    }, [marketId]);

    const { pathsMap, parentsMap } = useMemo(() => {
        const pathsMap = new Map<string | undefined, MenuEntry | undefined>();
        const parentsMap = new Map<string | undefined, MenuEntry | undefined>();

        traverseTree(menuEntries, TreeTraversalMethod.BFS, (node, parent) => {
            pathsMap.set(node.path, node);
            parentsMap.set(node.key, parent);
        });

        return { pathsMap, parentsMap };
    }, [menuEntries]);

    const selectedKey = pathsMap.get(pathname)?.key;
    const defaultOpenKey = parentsMap.get(selectedKey)?.key;

    return (
        <Box {...rest}>
            <Menu
                data-testid="app-sidebar-menu"
                mode="inline"
                theme="dark"
                items={menuEntries}
                inlineCollapsed={collapsed}
                defaultOpenKeys={defaultOpenKey ? [defaultOpenKey] : []}
                selectedKeys={selectedKey ? [selectedKey] : []}
            />
        </Box>
    );
};
