import { useContext } from 'react';
import { getScopes } from '@olxeu-monetization/auth-service-api-client';
import { AuthContext, ConfigContext } from '../context';

export const useAuthService = () => {
    const auth = useContext(AuthContext);
    const config = useContext(ConfigContext);

    const buildContext = async () => {
        return {
            baseUrl: config.services.authService.baseUrl,
            accessToken: await auth.getAccessToken(),
            market: auth.userMarketId
        };
    };

    return {
        getScopes: async () => {
            const context = await buildContext();
            return getScopes(context);
        }
    };
};
