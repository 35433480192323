import { FormattedMessage } from 'react-intl';
import {
    LanguageCode,
    MarketLanguage
} from '@olxeu-monetization/product-catalog-api-client';
import { Messages } from '../../intl';
import { TemplateFormPayload, TemplateFormValues } from './types';

const DEFAULT_LANGUAGE_CODE = 'en';

export const mapFormValuesToFormPayload = (
    formValues: TemplateFormValues
): TemplateFormPayload => {
    const formTranslations = formValues.translations;

    const translations = Object.keys(formTranslations)
        .filter((language) => formTranslations[language] !== undefined)
        .map((language) => ({
            language,
            value: formTranslations[language]
        }));

    return {
        name: formValues.name,
        id: formValues.id,
        description: formValues.description,
        status: formValues.status,
        translations
    };
};

export const mapFormDataToFormValues = (
    formPayload: TemplateFormPayload
): TemplateFormValues => {
    return {
        id: formPayload.id,
        name: formPayload.name,
        description: formPayload.description,
        status: formPayload.status,
        translations: formPayload.translations.reduce(
            (acc, translation) => ({
                ...acc,
                [translation.language]: translation.value
            }),
            {}
        )
    };
};

export const getLanguageLabel = (code: LanguageCode) => {
    switch (code) {
        case 'en':
            return <FormattedMessage {...Messages['common.language.en-us']} />;
        case 'bg_BG':
            return <FormattedMessage {...Messages['common.language.bg-bg']} />;
        case 'kk_KZ':
            return <FormattedMessage {...Messages['common.language.kk-kz']} />;
        case 'ru_KZ':
            return <FormattedMessage {...Messages['common.language.ru-kz']} />;
        case 'pl_PL':
            return <FormattedMessage {...Messages['common.language.pl-pl']} />;
        case 'pt_PT':
            return <FormattedMessage {...Messages['common.language.pt-pt']} />;
        case 'ro_RO':
            return <FormattedMessage {...Messages['common.language.ro-ro']} />;
        case 'uk_UA':
            return <FormattedMessage {...Messages['common.language.uk-ua']} />;
        case 'ru_UA':
            return <FormattedMessage {...Messages['common.language.ru-ua']} />;
        case 'uz_UZ':
            return <FormattedMessage {...Messages['common.language.uz-uz']} />;
        case 'oz_UZ':
            return <FormattedMessage {...Messages['common.language.oz-uz']} />;
        case 'ru_UZ':
            return <FormattedMessage {...Messages['common.language.ru-uz']} />;
        default:
            return '';
    }
};

export const getDefaultLanguage = (languages: MarketLanguage[]) =>
    languages.find((language) => language.code === DEFAULT_LANGUAGE_CODE);

export const getCurrentMarketLanguages = (languages: MarketLanguage[]) =>
    languages.filter((language) => language.code !== DEFAULT_LANGUAGE_CODE);
