import {
    CategorySetId,
    CategorySetType,
    OfferChangeId,
    OfferChangeStatus,
    ProductChangeType,
    ProductId,
    ProductStatus,
    TemplateId
} from '@olxeu-monetization/product-catalog-api-client';
import {
    LimitId,
    LimitStatus
} from '@olxeu-monetization/user-product-service-api-client';
import {
    EnumArrayQueryParam,
    EnumQueryParam,
    NumberArrayQueryParam,
    NumberQueryParam,
    StringArrayQueryParam,
    StringQueryParam
} from '../utils';

export enum MarketId {
    OLXPoland = 'olxpl',
    OLXUkraine = 'olxua',
    OLXKazakhstan = 'olxkz',
    OLXUzbekistan = 'olxuz',
    OLXPortugal = 'olxpt',
    OLXBulgaria = 'olxbg',
    OLXRomania = 'olxro'
}

export const marketNameMap = {
    [MarketId.OLXPoland]: 'OLX Poland',
    [MarketId.OLXUkraine]: 'OLX Ukraine',
    [MarketId.OLXKazakhstan]: 'OLX Kazakhstan',
    [MarketId.OLXUzbekistan]: 'OLX Uzbekistan',
    [MarketId.OLXPortugal]: 'OLX Portugal',
    [MarketId.OLXBulgaria]: 'OLX Bulgaria',
    [MarketId.OLXRomania]: 'OLX Romania'
};

export enum Path {
    Home = '/',
    SignIn = '/sign-in',
    SignInCallback = '/sign-in/callback',
    SignOutCallback = '/logout/callback',
    Market = '/:marketId',
    ProductList = '/:marketId/products',
    ProductDetails = '/:marketId/products/:id',
    ProductEdit = '/:marketId/products/:id/edit',
    OfferChangeList = '/:marketId/offer-changes',
    OfferChangeDetails = '/:marketId/offer-changes/:id',
    OfferChangeProductDetails = '/:marketId/offer-changes/:id/products/:productId',
    OfferChangeProductCreate = '/:marketId/offer-changes/:id/products/create',
    OfferChangeProductEdit = '/:marketId/offer-changes/:id/products/:productId/edit',
    CategorySetList = '/:marketId/category-sets',
    CategorySetDetails = '/:marketId/category-sets/:id',
    CategorySetCreate = '/:marketId/category-sets/create',
    CategorySetEdit = '/:marketId/category-sets/:id/edit',
    LimitList = '/:marketId/limits',
    LimitDetails = '/:marketId/limits/:id',
    LimitCreate = '/:marketId/limits/create',
    LimitEdit = '/:marketId/limits/:id/edit',
    TemplateList = '/:marketId/templates',
    TemplateDetails = '/:marketId/templates/:id',
    TemplateCreate = '/:marketId/templates/create',
    TemplateEdit = '/:marketId/templates/:id/edit'
}
export interface PathParams {
    [Path.Market]: {
        marketId: MarketId;
    };
    [Path.ProductList]: {
        marketId: MarketId;
    };
    [Path.ProductDetails]: {
        marketId: MarketId;
        id: ProductId;
    };
    [Path.ProductEdit]: {
        marketId: MarketId;
        id: ProductId;
    };
    [Path.OfferChangeList]: {
        marketId: MarketId;
    };
    [Path.OfferChangeDetails]: {
        marketId: MarketId;
        id: OfferChangeId;
    };
    [Path.OfferChangeProductDetails]: {
        marketId: MarketId;
        id: OfferChangeId;
        productId: ProductId;
    };
    [Path.OfferChangeProductCreate]: {
        marketId: MarketId;
        id: OfferChangeId;
    };
    [Path.OfferChangeProductEdit]: {
        marketId: MarketId;
        id: OfferChangeId;
        productId: ProductId;
    };
    [Path.CategorySetList]: {
        marketId: MarketId;
    };
    [Path.CategorySetDetails]: {
        marketId: MarketId;
        id: CategorySetId;
    };
    [Path.CategorySetCreate]: {
        marketId: MarketId;
    };
    [Path.CategorySetEdit]: {
        marketId: MarketId;
        id: CategorySetId;
    };
    [Path.LimitList]: {
        marketId: MarketId;
    };
    [Path.LimitDetails]: {
        marketId: MarketId;
        id: LimitId;
    };
    [Path.LimitCreate]: {
        marketId: MarketId;
    };
    [Path.LimitEdit]: {
        marketId: MarketId;
        id: LimitId;
    };
    [Path.TemplateList]: {
        marketId: MarketId;
    };
    [Path.TemplateDetails]: {
        marketId: MarketId;
        id: TemplateId;
    };
    [Path.TemplateCreate]: {
        marketId: MarketId;
    };
    [Path.TemplateEdit]: {
        marketId: MarketId;
        id: TemplateId;
    };
}

export const QueryParamsSchemas = {
    [Path.ProductList]: {
        search: new StringQueryParam(),
        types: new StringArrayQueryParam(),
        status: new EnumQueryParam([
            ProductStatus.Enabled,
            ProductStatus.Disabled
        ]),
        categorySetIds: new StringArrayQueryParam(),
        featureIds: new StringArrayQueryParam(),
        limit: new NumberQueryParam(),
        page: new NumberQueryParam()
    },
    [Path.OfferChangeList]: {
        search: new StringQueryParam(),
        status: new EnumQueryParam([
            OfferChangeStatus.Draft,
            OfferChangeStatus.Published,
            OfferChangeStatus.Cancelled
        ] as const),
        limit: new NumberQueryParam(),
        page: new NumberQueryParam()
    },
    [Path.OfferChangeDetails]: {
        changeType: new EnumQueryParam([
            ProductChangeType.NewProduct,
            ProductChangeType.Updated
        ]),
        search: new StringQueryParam(),
        types: new StringArrayQueryParam(),
        status: new EnumQueryParam([
            ProductStatus.Enabled,
            ProductStatus.Disabled
        ]),
        categorySetIds: new StringArrayQueryParam(),
        featureIds: new StringArrayQueryParam(),
        limit: new NumberQueryParam(),
        page: new NumberQueryParam()
    },
    [Path.CategorySetList]: {
        name: new StringQueryParam(),
        types: new EnumArrayQueryParam([
            CategorySetType.Limit,
            CategorySetType.Package,
            CategorySetType.Megapackage
        ]),
        categoryIds: new NumberArrayQueryParam(),
        limit: new NumberQueryParam(),
        page: new NumberQueryParam()
    },
    [Path.LimitList]: {
        status: new EnumQueryParam([LimitStatus.Active, LimitStatus.Disabled]),
        categorySetIds: new StringArrayQueryParam(),
        limit: new NumberQueryParam(),
        page: new NumberQueryParam()
    },
    [Path.TemplateList]: {
        search: new StringQueryParam(),
        limit: new NumberQueryParam(),
        page: new NumberQueryParam()
    }
} as const;
