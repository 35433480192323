import { useIntl } from 'react-intl';
import { AugmentedRoute, Button, Tag } from 'antd';
import { useTheme } from 'styled-components';
import { AccessScope } from '@olxeu-monetization/auth-service-api-client';
import {
    CategorySetId,
    Feature,
    OfferChangeDetails,
    OfferChangeProduct,
    ProductId,
    ProductType
} from '@olxeu-monetization/product-catalog-api-client';
import { BottomBar } from '../../components/BottomBar';
import { ConfirmAction } from '../../components/ConfirmAction';
import {
    DraftOfferChangeProducts,
    DraftOfferChangeProperties,
    Filters
} from '../../components/OfferChangeSections';
import { PageHeader } from '../../components/PageHeader';
import { PermissionGuard } from '../../components/PermissionGuard';
import { useFeatureFlag } from '../../helpers/useFeatureFlag';
import { Messages } from '../../intl';
import { MarketId } from '../../routing';
import { FeatureFlag } from '../../types/flags';

interface Props {
    marketId: MarketId;
    offerChange: OfferChangeDetails;
    offerChangeProducts: OfferChangeProduct[];
    productsTotalCount: number;
    breadcrumbRoutes: AugmentedRoute[];
    categorySetNamesMap: Map<CategorySetId, string>;
    features: Feature[];
    productTypes: ProductType[];
    pageIndex: number;
    pageSize: number;
    filters: Filters;
    nameEditLoading: boolean;
    nameEditEnabled: boolean;
    onNameEditToggle?: (enabled: boolean) => void;
    onPageIndexChange?: (pageIndex: number) => void;
    onPageSizeChange?: (pageSize: number) => void;
    onFiltersChange?: (filters: Filters) => void;
    onProductsRemove?: (ids: ProductId[]) => void;
    onNameEditSubmit?: (name: string) => void;
    onOfferChangePublish?: () => void;
    onOfferChangeCancel?: () => void;
}

export const DraftOfferChangeDetailsLayout = ({
    marketId,
    offerChange,
    offerChangeProducts,
    productsTotalCount,
    breadcrumbRoutes,
    categorySetNamesMap,
    features,
    productTypes,
    pageIndex,
    pageSize,
    filters,
    nameEditLoading,
    nameEditEnabled,
    onNameEditToggle,
    onFiltersChange,
    onProductsRemove,
    onPageIndexChange,
    onPageSizeChange,
    onNameEditSubmit,
    onOfferChangePublish,
    onOfferChangeCancel
}: Props) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();

    const isPublishingDisabled = useFeatureFlag(
        FeatureFlag.DisableOfferChangePublishing
    );

    return (
        <div data-testid="draft-offer-change-details-layout">
            <PageHeader
                title={formatMessage(Messages['common.label.offer-change'])}
                tags={
                    <Tag color={theme.colors.tagIdentifier}>
                        {formatMessage(Messages['common.tag.id'], {
                            id: offerChange.id
                        })}
                    </Tag>
                }
                breadcrumbRoutes={breadcrumbRoutes}
            />
            <DraftOfferChangeProperties
                offerChange={offerChange}
                nameEditLoading={nameEditLoading}
                nameEditEnabled={nameEditEnabled}
                onNameEditToggle={onNameEditToggle}
                onNameEditSubmit={onNameEditSubmit}
            />
            <DraftOfferChangeProducts
                marketId={marketId}
                offerChangeId={offerChange.id}
                offerChangeProducts={offerChangeProducts}
                productsTotalCount={productsTotalCount}
                categorySetNamesMap={categorySetNamesMap}
                features={features}
                productTypes={productTypes}
                pageIndex={pageIndex}
                pageSize={pageSize}
                filters={filters}
                onPageIndexChange={onPageIndexChange}
                onPageSizeChange={onPageSizeChange}
                onFiltersChange={onFiltersChange}
                onProductsRemove={onProductsRemove}
            />
            <BottomBar>
                <PermissionGuard
                    permissions={[AccessScope.ProductCatalogOfferChangeWrite]}
                    placement="left"
                >
                    {(eligible) => (
                        <ConfirmAction
                            title={formatMessage(
                                Messages[
                                    'common.propmt-message.remove-offer-change-confirm'
                                ]
                            )}
                            disabled={!productsTotalCount || !eligible}
                            onConfirm={() => onOfferChangeCancel?.()}
                        >
                            <Button
                                danger
                                disabled={!productsTotalCount || !eligible}
                            >
                                {formatMessage(Messages['common.button.abort'])}
                            </Button>
                        </ConfirmAction>
                    )}
                </PermissionGuard>
                <PermissionGuard
                    permissions={[AccessScope.ProductCatalogOfferChangePublish]}
                    placement="left"
                >
                    {(eligible) => (
                        <ConfirmAction
                            title={formatMessage(
                                Messages[
                                    'common.propmt-message.publish-offer-change-confirm'
                                ]
                            )}
                            disabled={
                                !productsTotalCount ||
                                !eligible ||
                                isPublishingDisabled
                            }
                            onConfirm={() => onOfferChangePublish?.()}
                        >
                            <Button
                                type="primary"
                                disabled={
                                    !productsTotalCount ||
                                    !eligible ||
                                    isPublishingDisabled
                                }
                            >
                                {formatMessage(
                                    Messages['common.button.publish']
                                )}
                            </Button>
                        </ConfirmAction>
                    )}
                </PermissionGuard>
            </BottomBar>
        </div>
    );
};
