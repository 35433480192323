import { createContext } from 'react';
import { UserScopes } from '@olxeu-monetization/auth-service-api-client';

export interface UserScopesContextType {
    data?: UserScopes;
    loading: boolean;
    error?: Error;
}

const DefaultUserScopesContext: UserScopesContextType = {
    data: undefined,
    loading: false,
    error: undefined
};

export const UserScopesContext = createContext<UserScopesContextType>(
    DefaultUserScopesContext
);
