import { FormattedMessage, IntlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import { AugmentedColumnsType, Button, Space } from 'antd';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { AccessScope } from '@olxeu-monetization/auth-service-api-client';
import {
    Category,
    CategoryId,
    CategorySetId,
    CategorySetType
} from '@olxeu-monetization/product-catalog-api-client';
import { PermissionGuard } from '../../components/PermissionGuard';
import {
    CategoriesCell,
    renderSearchIcon,
    SearchFilter,
    TreeSelectFilter
} from '../../components/Table';
import { Messages } from '../../intl';
import { buildLink, MarketId, Path } from '../../routing';

export interface Filters {
    name?: string;
    types?: CategorySetType[];
    categoryIds?: CategoryId[];
}

export type TableDataSource = {
    key: string;
    id: CategorySetId;
    name: string;
    type: CategorySetType;
    categories: Category[];
};

export type TableFilters = {
    name: string[] | null;
    types: CategorySetType[] | null;
    categories: CategoryId[] | null;
    id: null;
};

interface Options {
    categoryOptions?: ColumnFilterItem[];
    typeOptions?: ColumnFilterItem[];
    filters: Filters;
    marketId: MarketId;
    intl: IntlShape;
}

export const buildTableColumns = ({
    categoryOptions,
    typeOptions,
    filters,
    marketId,
    intl
}: Options): AugmentedColumnsType<TableDataSource, TableFilters> => {
    return [
        {
            title: <FormattedMessage {...Messages['common.label.name']} />,
            key: 'name',
            dataIndex: 'name',
            filterDropdown: (props) => (
                <SearchFilter
                    {...props}
                    placeholder={intl.formatMessage(
                        Messages['filters.placeholder.name-search']
                    )}
                />
            ),
            filterIcon: renderSearchIcon,
            filteredValue: filters.name ? [filters.name] : []
        },
        {
            title: <FormattedMessage {...Messages['common.label.type']} />,
            key: 'types',
            dataIndex: 'type',
            filters: typeOptions,
            filterMultiple: false,
            filteredValue: filters.types ?? []
        },
        {
            title: (
                <FormattedMessage {...Messages['common.label.categories']} />
            ),
            key: 'categories',
            dataIndex: 'categories',
            render: (categories) => (
                <CategoriesCell
                    data-cy="category-set-table-categories-cell"
                    categories={categories}
                />
            ),
            filterDropdown: (props) => (
                <TreeSelectFilter
                    {...props}
                    placeholder={intl.formatMessage(
                        Messages['filters.placeholder.category-search']
                    )}
                />
            ),
            filters: categoryOptions,
            filteredValue: filters.categoryIds ?? []
        },
        {
            title: <FormattedMessage {...Messages['common.label.actions']} />,
            key: 'id',
            dataIndex: 'id',
            align: 'right',
            render: (id) => (
                <Space>
                    <Link
                        to={buildLink(Path.CategorySetDetails, {
                            id: id,
                            marketId
                        })}
                    >
                        <Button size="small">
                            <FormattedMessage
                                {...Messages['common.button.open']}
                            />
                        </Button>
                    </Link>
                    <PermissionGuard
                        permissions={[
                            AccessScope.ProductCatalogCategorySetWrite
                        ]}
                        placement="left"
                    >
                        {(eligible) => (
                            <Link
                                to={buildLink(Path.CategorySetEdit, {
                                    id: id,
                                    marketId
                                })}
                            >
                                <Button size="small" disabled={!eligible}>
                                    <FormattedMessage
                                        {...Messages['common.button.edit']}
                                    />
                                </Button>
                            </Link>
                        )}
                    </PermissionGuard>
                </Space>
            )
        }
    ];
};
