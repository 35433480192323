export enum MarketId {
    OLXPoland = 'olxpl',
    OLXUkraine = 'olxua',
    OLXKazakhstan = 'olxkz',
    OLXUzbekistan = 'olxuz',
    OLXPortugal = 'olxpt',
    OLXBulgaria = 'olxbg',
    OLXRomania = 'olxro'
}

type UUID = string;

export type ClientId = UUID;

export enum AccessScope {
    ProductCatalogCategorySetRead = 'productcatalog/categoryset/read',
    ProductCatalogCategorySetWrite = 'productcatalog/categoryset/write',
    ProductCatalogFeatureDefinitionRead = 'productcatalog/featuredefinition/read',
    ProductCatalogFeatureDefinitionWrite = 'productcatalog/featuredefinition/write',
    ProductCatalogOfferChangePublish = 'productcatalog/offerchange/publish',
    ProductCatalogOfferChangeRead = 'productcatalog/offerchange/read',
    ProductCatalogOfferChangeWrite = 'productcatalog/offerchange/write',
    ProductCatalogProductRead = 'productcatalog/product/read',
    ProductCatalogTemplateRead = 'productcatalog/template/read',
    ProductCatalogTemplateWrite = 'productcatalog/template/write',
    ProductCatalogUiAccess = 'productcatalog/ui/access',
    UpsLimitRead = 'ups/limit/read',
    UpsLimitWrite = 'ups/limit/write'
}

export interface UserScopes {
    client_id: ClientId;
    client_name: MarketId;
    scopes: AccessScope[];
    email: string;
}
