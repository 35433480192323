import { Buffer } from 'buffer';
import { AccessScope } from '@olxeu-monetization/auth-service-api-client';
import { MarketId } from '../../routing';
import { OktaAccessScope } from './types';

export const hasProductCatalogUiAccess = (scopes: AccessScope[]) => {
    const regex = /productcatalog\/ui\/access$/;
    return scopes.some((scope) => regex.test(scope));
};

export const parseJwt = <T = Record<string, unknown>>(encodedJwt: string) => {
    const segments = encodedJwt.split('.');
    const dataSegment = segments[1];
    const decodedData = Buffer.from(dataSegment, 'base64').toString('utf8');

    return JSON.parse(decodedData) as T;
};

export const getMarketsFromJwtRoles = (
    roles: OktaAccessScope[],
    marketCandidates: typeof MarketId
): MarketId[] => {
    const pattern =
        /app_mh_(?:stg|prd)_([a-z]+)_(?:monetization|productmanager)/;

    const markets = roles
        .map((item) => {
            const match = item.match(pattern);

            if (match && match[1]) {
                return Object.values(marketCandidates).find(
                    (value) => value === match[1]
                );
            }

            return undefined;
        })
        .filter((item): item is MarketId => item !== undefined);

    return Array.from(new Set(markets));
};

export const parseMarketId = (marketIdCandidate: string) => {
    const markets = Object.values(MarketId);
    return markets.find((marketId) => {
        return marketId === marketIdCandidate;
    });
};
