import { AccessScope } from '@olxeu-monetization/auth-service-api-client';

export const isPermissionGrantedForScope = (
    userScopes: string[],
    scopeCandidates: AccessScope[]
): userScopes is AccessScope[] => {
    return scopeCandidates.some((scopeCandidate) =>
        userScopes.includes(scopeCandidate)
    );
};
