import { useContext } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { ConfigContext } from '../../context';
import { SignInLayout } from '../../layouts/SignInLayout';

export const SignInPage = () => {
    const { oktaAuth } = useOktaAuth();

    const configContext = useContext(ConfigContext);

    const handleLogin = () => {
        void oktaAuth.signInWithRedirect({
            clientId: configContext.services.oktaAuthService.clientId
        });
    };

    return <SignInLayout onLogin={handleLogin} />;
};
