import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { AccessScope } from '@olxeu-monetization/auth-service-api-client';
import {
    OfferChangeId,
    ProductId
} from '@olxeu-monetization/product-catalog-api-client';
import { Messages } from '../../intl';
import { buildLink, MarketId, Path } from '../../routing';
import { PermissionGuard } from '../PermissionGuard';
import { ProductLockedWarning } from '../ProductLockedWarning';

interface Props {
    productId: ProductId;
    offerChangeId?: OfferChangeId;
    marketId: MarketId;
    size?: 'small' | 'middle' | 'large';
}

export const EditProductAction = ({
    productId,
    offerChangeId,
    marketId,
    size = 'middle'
}: Props) => {
    const { formatMessage } = useIntl();

    if (offerChangeId !== undefined) {
        return (
            <ProductLockedWarning
                marketId={marketId}
                offerChangeId={offerChangeId}
            >
                <Button size={size} disabled={true}>
                    {formatMessage(Messages['common.button.edit'])}
                </Button>
            </ProductLockedWarning>
        );
    }

    return (
        <PermissionGuard
            permissions={[AccessScope.ProductCatalogOfferChangeWrite]}
            placement="left"
        >
            {(eligible) => (
                <Link
                    to={buildLink(Path.ProductEdit, {
                        id: productId,
                        marketId
                    })}
                >
                    <Button size={size} disabled={!eligible}>
                        {formatMessage(Messages['common.button.edit'])}
                    </Button>
                </Link>
            )}
        </PermissionGuard>
    );
};
